
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import RequestForm from "../../components/RequestFormComponent.vue";

@Component({
  components: {
    RequestForm
  }
})
export default class RequestCreatePage extends Vue {
  @Watch("formData.date_from")
  setDateTo(date?: string) {
    if (!date) {
      this.formData["date_to"] = "";
      return;
    }

    const tomorrow = moment(date).add(1, "day");
    this.formData["date_to"] = tomorrow.format("YYYY-MM-DD");
  }

  formData = {
    date_from: "",
    date_to: ""
  };

  confirmation = false;
  errors = null;

  request() {
    this.errors = null;

    this.$store
      .dispatch("vacation/request", this.formData)
      .then(() => (this.confirmation = true))
      .catch(error => (this.errors = error.response.data.errors));
  }

  confirm() {
    this.$router.push({
      name: "vacation.requests.index"
    });
  }
}
