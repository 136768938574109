
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import type { Task } from '../store'

type TaskData = {
  id: number | number[];
  action: string;
};

@Component({
  components: {
    TaskInfo: () =>
      import("@/app/modules/tasks/components/TaskInfoComponent.vue"),
    ScheduledTasks: () =>
      import("@/app/modules/tasks/components/ScheduledTasksComponent.vue"),
    TaskReport: () =>
      import("@/app/modules/tasks/components/TaskReportComponent.vue")
  },
  filters: {
    date_from(value) {
      if (!value) return;

      return moment(value).format("DD.MM.YYYY");
    },

    task_duration(value, t) {
      const h = ((value * 60) / 60) | 0;
      const m = Math.round((value * 60) % 60);

      return `${h}${t} ${m}m`;
    }
  }
})
export default class TaskPage extends Vue {
  @Prop(Number) readonly taskId!: number;

  @Watch("$route.params.id")
  onRouteParamsIdChanged() {
    this.fetchTasks();
    this.fetchTaskById();
  }

  @Watch("$route.query")
  onRouteQueryChanged() {
    this.fetchTaskById();
  }

  task: null | Task = null;
  localTask: null | Task = null;
  errors = null;

  acceptDialog = false;
  finishDialog = false;

  mounted() {
    if (!this.tasksData) this.fetchTasks();
    if (!this.localTask) this.fetchTaskById();
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get tasksData() {
    return this.$store.getters["tasks/data"];
  }

  get hasAdditionalInfo(): boolean {
    return this.task?.order.key_deposited != null || this.task?.order.description != null || this.task?.info != null;
  }

  get scheduledTasks() {
    return this.tasksData?.filter(
      (task: Task) =>
        task.is_multi_task && task.multi_task_id == this.task?.multi_task_id
    );
  }

  get allowedEditing(): boolean {
    const addedMonth = moment(this.task?.date_from).add(1, "month");
    const limitsDate = moment([addedMonth.year(), addedMonth.month(), 5]);

    return limitsDate.isAfter(moment());
  }

  get isAfter() {
    return moment(this.task?.date_from).isAfter(moment());
  }

  get isApiFetching() {
    return this.$store.getters["api/isApiFetching"];
  }

  fetchTaskById() {
    this.$store.dispatch("tasks/fetchById", this.taskId).then(({ data }) => {
      this.task = data.data;
      this.localTask = this.task;
    });
  }

  fetchTasks() {
    this.$store.dispatch("tasks/fetchAll").then(({ data }) => {
      this.$store.commit("tasks/setData", data.data);
    });
  }

  statusIdIncluded(id: number[] = []): boolean {
    return id.includes(this.task?.status_id ?? 0);
  }

  acceptTask() {
    const data: TaskData = {
      id: this.taskId,
      action: "tasks/accept"
    };

    if (this.scheduledTasks.length > 0) {
      data.id = this.scheduledTasks.map(task => task.id);
      data.action = "tasks/acceptMulti";
    }

    this.$store
      .dispatch(
        data.action,
        Array.isArray(data.id) ? { ids: data.id } : data.id
      )
      .then(() => {
        this.$router.back();
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })
      .finally(() => {
        this.acceptDialog = false;
      });
  }

  finishTask() {
    const data = {
      id: this.taskId,
      real_time_from: this.task?.real_time_from,
      real_time_to: this.task?.real_time_to,
      notes: this.task?.notes,
    };

    this.$store
      .dispatch("tasks/finish", data)
      .then(() => {
        this.$router.back();
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })
      .finally(() => {
        this.finishDialog = false;
      });
  }
}
