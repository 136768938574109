import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";

import Api from "@/app/modules/_global/classes/api/Api";

export type Task = {
  id?: number;
  color: string;
  date_from: Date;
  time_from: string;
  time_to: string;
  duration: number;
  real_time_from: string;
  real_time_to: string;
  status_id: number;
  status?: {
    id: number;
    name: string;
  };
  info: string;
  order: {
    order_number: string;
    key_deposited?: string;
    description?: string;
    customer: string;
    street: string;
    zip: string;
    city: string;
    apartment?: number;
    floor?: number;
    service: {
      translation: Record<string, string>;
    };
  };
  notes: string;
  is_multi_task?: number;
  multi_task_id?: number;
};

config.rawError = true;

@Module({ namespaced: true })
export default class TasksStore extends VuexModule {
  private _data: null | object = null;

  private _status = 0;
  private _period = 0;

  get data(): null | object {
    return this._data;
  }

  get status(): number {
    return this._status;
  }

  get period(): number {
    return this._period;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setStatus(data: number): void {
    this._status = data;
  }

  @Mutation
  setPeriod(data: number): void {
    this._period = data;
  }

  @Action
  fetchAll() {
    return Api.get("/api/planning/apps/tasks");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/planning/apps/event/${id}`);
  }

  @Action
  fetchRepetitiveOrders() {
    return Api.get("/api/planning/filters/repetitive-orders");
  }

  @Action
  taskReport(data: object) {
    return Api.post("/api/planning/apps/task-report", data);
  }

  @Action
  accept(id: number) {
    return Api.patch(`/api/planning/apps/event/accept/${id}`);
  }

  @Action
  acceptMulti(data: []) {
    return Api.patch("/api/planning/apps/event/multi/accept", data);
  }

  @Action
  finish(data: { id: number }) {
    return Api.patch(`/api/planning/apps/event/finish/${data.id}`, data);
  }
}
